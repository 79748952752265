import ApiService from "@/core/services/api/v2/api.service";

const LoginService = {
  login(email, password) {
    return new Promise(resolve => {
      let credentials = {
        Email: email,
        Password: password
      };

      ApiService.post("login", credentials)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  login2(companyID) {
    return new Promise(resolve => {
      ApiService.put("login2", { CompanyID: companyID })
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  forgotPassword(email) {
    return new Promise(resolve => {
      ApiService.post("forgotPassword", { Email: email })
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  resetPassword(rememberToken, passwordNew, passwordConfirm) {
    return new Promise(resolve => {
      let newData = {
        RememberToken: rememberToken,
        PasswordNew: passwordNew,
        PasswordConfirm: passwordConfirm
      };

      ApiService.put("resetPassword", newData)
        .then(response => {
          resolve(response.data.response);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  },

  logout() {
    return new Promise(resolve => {
      ApiService.delete("logout")
        .then(response => {
          resolve(response.status);
        })
        .catch(error => {
          resolve(error.response.status);
        });
    });
  }
};

export default LoginService;
