<template>
  <!--begin::ForgotPassword-->
  <div class="forgot-form">
    <v-form class="form" ref="formForgot" v-model="valid" lazy-validation>
      <div class="login-title">
        {{ $t("AUTH.FORGOT.RECOVER_TITLE") }}
        <hr class="login-title-sub mb-3" />
      </div>

      <h6 class="mt-0 mb-9">
        {{ $t("AUTH.FORGOT.RECOVER_DESC") }}
      </h6>

      <div class="form-group">
        <label>
          {{ $t("MY_PROFILE.EMAIL") }}
        </label>
        <v-text-field
          class="form-control"
          v-model="email"
          :placeholder="$t('MY_PROFILE.EMAIL')"
          autocomplete="off"
          :rules="[rules.required, rules.email]"
          required
        >
          <template v-slot:prepend-inner>
            <inline-svg src="/media/icons/contact-gray.svg" />
          </template>
        </v-text-field>
      </div>

      <div class="py-6 text-center">
        <button
          ref="formForgotSubmit"
          class="btn btn-pill btn-tertiary"
          :disabled="!valid"
          @click="onSubmitForgot"
        >
          {{ $t("AUTH.FORGOT.RECOVER") }}
        </button>
      </div>
      <div class="text-center">
        <router-link to="/login">
          <u class="black">{{ $t("AUTH.FORGOT.BACK_TO_LOGIN") }}</u>
        </router-link>
      </div>
    </v-form>
  </div>
  <!--end::ForgotPassword-->
</template>

<script>
import LoginService from "@/core/services/api/v2/login.service";

export default {
  name: "ForgotPassword",
  data() {
    return {
      valid: true,
      email: "",
      rules: {
        required: value => !!value || this.$i18n.t("VALIDATION.REQUIRED_FIELD"),
        email: value =>
          !!/.+@.+\..+/.test(value) || this.$i18n.t("VALIDATION.INVALID_EMAIL")
      }
    };
  },
  mounted() {
    // Set parent layout content
    this.$emit(
      "layout-login-aside-background",
      process.env.BASE_URL + "media/bg/imagen-bienvenido.jpg"
    );
    this.$emit(
      "layout-login-aside-title",
      this.$i18n.t("AUTH.LOGIN.ASIDE_TITLE")
    );
    this.$emit(
      "layout-login-aside-description",
      this.$i18n.t("AUTH.LOGIN.ASIDE_DESCRIPTION")
    );
  },
  methods: {
    onSubmitForgot(e) {
      e.preventDefault();

      if (this.$refs.formForgot.validate()) {
        // Disable submit button
        this.valid = false;

        // Send request
        LoginService.forgotPassword(this.email).then(statusCode => {
          if (statusCode < 400) {
            this.$bvToast.toast(this.$i18n.t("AUTH.FORGOT.RECOVER_OK"), {
              title: this.$i18n.t("GENERIC.SAVE_OK"),
              variant: "success",
              noCloseButton: true,
              solid: true
            });
          } else {
            this.$bvToast.toast(this.$i18n.t("AUTH.LOGIN.WRONG_CREDENTIALS"), {
              title: this.$i18n.t("GENERIC.ERROR"),
              variant: "danger",
              noCloseButton: true,
              solid: true
            });

            // Reset submit button
            this.valid = false;
            this.email = "";
          }
        });
      }
    }
  }
};
</script>
